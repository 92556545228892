var exports = {};

exports = function (parentMedia, childMedia) {
  if (!parentMedia.length && childMedia.length) return childMedia;
  if (parentMedia.length && !childMedia.length) return parentMedia;
  if (!parentMedia.length && !childMedia.length) return [];
  const media = [];
  parentMedia.forEach(parentItem => {
    childMedia.forEach(childItem => {
      if (parentItem !== childItem) media.push(`${parentItem} and ${childItem}`);
    });
  });
  return media;
};

export default exports;