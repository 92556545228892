import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _sugarss2 from "sugarss";

var _sugarss = "default" in _sugarss2 ? _sugarss2.default : _sugarss2;

var exports = {};
// builtin tooling
const path = _path; // placeholder tooling

let sugarss;

exports = function processContent(result, content, filename, options, postcss) {
  const {
    plugins
  } = options;
  const ext = path.extname(filename);
  const parserList = []; // SugarSS support:

  if (ext === ".sss") {
    if (!sugarss) {
      try {
        sugarss = _sugarss;
      } catch {} // Ignore

    }

    if (sugarss) return runPostcss(postcss, content, filename, plugins, [sugarss]);
  } // Syntax support:


  if (result.opts.syntax && result.opts.syntax.parse) {
    parserList.push(result.opts.syntax.parse);
  } // Parser support:


  if (result.opts.parser) parserList.push(result.opts.parser); // Try the default as a last resort:

  parserList.push(null);
  return runPostcss(postcss, content, filename, plugins, parserList);
};

function runPostcss(postcss, content, filename, plugins, parsers, index) {
  if (!index) index = 0;
  return postcss(plugins).process(content, {
    from: filename,
    parser: parsers[index]
  }).catch(err => {
    // If there's an error, try the next parser
    index++; // If there are no parsers left, throw it

    if (index === parsers.length) throw err;
    return runPostcss(postcss, content, filename, plugins, parsers, index);
  });
}

export default exports;